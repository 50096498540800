import React from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import BlogOverview from "./Blog/BlogOverview";
import Post from "./Blog/Post";
import About from "./About";
import Homepage from "./Homepage";
import ErrorPage from "./ErrorPage";
import Moments from "./Moments";
export default function App() {
    return (React.createElement(React.Fragment, null,
        React.createElement(Navbar, null),
        React.createElement("main", null,
            React.createElement(Routes, null,
                React.createElement(Route, { path: "/", element: React.createElement(Homepage, null) }),
                React.createElement(Route, { path: "about", element: React.createElement(About, null) }),
                React.createElement(Route, { path: "moments", element: React.createElement(Moments, null) }),
                React.createElement(Route, { path: "blog", element: React.createElement(BlogOverview, null) }),
                React.createElement(Route, { path: "blog/:articleId", element: React.createElement(Post, null) }),
                React.createElement(Route, { path: "*", element: React.createElement(ErrorPage, null) }))),
        React.createElement(Footer, null)));
}
