import React from "react";
import moments from "assets/photos/meta";
const allLocations = Object.keys(moments).sort((a, b) => (moments[a].location >= moments[b].location ? 1 : -1));
export default function Filter(props) {
    const { location, setLocation } = props;
    return (React.createElement("div", { id: "filter-container" },
        React.createElement("div", { className: "filter-title" }, "Location: "),
        React.createElement("div", { className: "filter-buttons" },
            React.createElement("button", { onClick: () => {
                    setLocation({ locations: allLocations.join(",") });
                } }, "Select All"),
            React.createElement("button", { onClick: () => {
                    setLocation({ locations: "" });
                } }, "Deselect All")),
        React.createElement("div", { className: "filter" }, allLocations.map((key) => (React.createElement("div", { key: key, className: "filter-option" },
            React.createElement("input", { type: "checkbox", name: key, id: key, checked: location.includes(key), onChange: () => {
                    let newLocation = "";
                    if (location.includes(key)) {
                        newLocation = location.filter((name) => name !== key).join(",");
                    }
                    else {
                        if (location.length == 0) {
                            newLocation = key;
                        }
                        else {
                            newLocation = [key, ...location].join(",");
                        }
                    }
                    setLocation({ locations: newLocation });
                } }),
            React.createElement("label", { htmlFor: key }, moments[key].location)))))));
}
