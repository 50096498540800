export function lazyLoadImages(images) {
    const lazyImageObserver = new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (entry) {
            const lazyImage = entry.target;
            let loaded = false;
            if (entry.isIntersecting) {
                lazyImage.src = lazyImage.dataset.src;
                lazyImage.onload = () => {
                    loaded = true;
                };
            }
            else {
                if (loaded) {
                    lazyImageObserver.unobserve(lazyImage);
                }
                else {
                    lazyImage.src = "/assets/icon/transparent.png";
                }
            }
        });
    });
    images.forEach(function (lazyImage) {
        lazyImageObserver.observe(lazyImage);
    });
}
