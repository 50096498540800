import React, { useEffect, useState } from "react";
import moments from "assets/photos/meta";
import Filter from "./Filter";
import ModalPhoto from "./ModalPhoto";
import { lazyLoadImages } from "src/utils";
import { useSearchParams } from "react-router-dom";
export default function Moments() {
    const [locationParams, setLocationParams] = useSearchParams();
    const [locations, setLocations] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalPhoto, setModalPhoto] = useState({ key: "bremen", index: 0 });
    useEffect(() => {
        try {
            const locs = locationParams
                .get("locations")
                .split(",")
                .map((key) => (moments[key] ? key : ""))
                .filter((str) => str);
            setLocations(locs);
            let locationStr = "";
            if (locs.length >= 4) {
                locationStr = "Moments in multiple places";
            }
            else if (locs.length > 1 && locs.length < 4) {
                locationStr =
                    "Moments in " +
                        locs
                            .map((key) => moments[key].location)
                            .slice(0, locs.length - 1)
                            .join(", ") +
                        ` and ${moments[locs[locs.length - 1]].location}`;
            }
            else if (locs.length == 1) {
                locationStr = "Moments in " + moments[locs[0]].location;
            }
            else if (locs.length === 0) {
                locationStr = "Moments - Shengyu Huang";
            }
            document.title = locationStr;
        }
        catch {
            setLocationParams({ locations: Object.keys(moments).join(",") });
        }
    }, [locationParams]);
    useEffect(() => {
        const container = document.getElementById("moment-container");
        if (container) {
            const images = [].slice.call(container.querySelectorAll("img"));
            lazyLoadImages(images);
        }
    }, [locations]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalPhoto, { modal: modal, setModal: setModal, photo: modalPhoto, setModalPhoto: setModalPhoto, locations: locations }),
        React.createElement("div", { id: "moment-container" },
            React.createElement(Filter, { location: locations, setLocation: setLocationParams }),
            React.createElement("div", { id: "moment-overview" }, locations.map((key) => [...Array(moments[key].numPhoto).keys()].map((index) => {
                if (!moments[key].excluded.includes(index)) {
                    return (React.createElement("div", { className: "image-container", key: `${key}-${index}`, onClick: () => {
                            setModal(true);
                            setModalPhoto({ key, index });
                        } },
                        React.createElement("img", { src: "/assets/icon/transparent.png", "data-src": `/assets/photos/${key}/${key}-${index}-small.jpeg`, alt: `${moments[key].location}-img` })));
                }
                else {
                    return null;
                }
            }))))));
}
