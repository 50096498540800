import React, { useEffect, useRef, useState } from "react";
import moments from "assets/photos/meta";
export default function ModalPhoto(props) {
    const { modal, setModal, photo, setModalPhoto, locations } = props;
    const [photoLocation, setPhotoLocation] = useState("");
    const image = useRef();
    useEffect(() => {
        setPhotoLocation(moments[photo.key].location);
    }, [photo.key]);
    function nextPhoto() {
        if (moments[photo.key].numPhoto - 1 > photo.index) {
            setModalPhoto({ key: photo.key, index: photo.index + 1 });
        }
        else {
            const index = locations.indexOf(photo.key);
            let key = photo.key;
            if (index === locations.length - 1) {
                key = locations[0];
            }
            else {
                key = locations[index + 1];
            }
            setModalPhoto({ key, index: 0 });
        }
    }
    function previousPhoto() {
        if (photo.index > 0) {
            setModalPhoto({ key: photo.key, index: photo.index - 1 });
        }
        else {
            const index = locations.indexOf(photo.key);
            let key = photo.key;
            if (index > 0) {
                key = locations[index - 1];
            }
            else {
                key = locations[locations.length - 1];
            }
            setModalPhoto({ key, index: moments[key].numPhoto - 1 });
        }
    }
    useEffect(() => {
        const imageRef = image.current;
        if (!modal || !imageRef) {
            return;
        }
        let touchXStart = 0, touchXEnd = 0, touchYStart = 0, touchYEnd = 0, swipe = false;
        function handleTouchStart(event) {
            touchXStart = event.touches[0].screenX;
            touchYStart = event.touches[0].screenY;
            swipe = event.touches.length == 1;
        }
        function handleTouchEnd(event) {
            if (!swipe) {
                return;
            }
            touchXEnd = event.changedTouches[0].screenX;
            touchYEnd = event.changedTouches[0].screenY;
            const diffX = touchXEnd - touchXStart, diffY = Math.abs(touchYEnd - touchYStart);
            console.log(diffY);
            if (diffY <= 100) {
                if (diffX > 40) {
                    previousPhoto();
                }
                else if (diffX < -40) {
                    nextPhoto();
                }
            }
        }
        imageRef.addEventListener("touchstart", handleTouchStart);
        imageRef.addEventListener("touchend", handleTouchEnd);
        function handleKeyDown(event) {
            if (event.key === "ArrowRight") {
                nextPhoto();
            }
            else if (event.key === "ArrowLeft") {
                previousPhoto();
            }
        }
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            imageRef.removeEventListener("touchstart", handleTouchStart);
            imageRef.removeEventListener("touchend", handleTouchEnd);
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [modal, locations, photo]);
    useEffect(() => {
        const imageRef = image.current;
        if (modal) {
            imageRef.src = `/assets/photos/${photo.key}/${photo.key}-${photo.index}-medium.jpeg`;
        }
        else {
            imageRef.src = "/assets/icon/transparent.png";
        }
    }, [modal, photo]);
    return (React.createElement("div", { className: "picture-frame", style: { display: modal ? "flex" : "none" }, onClick: () => {
            setModal(false);
        } },
        React.createElement("div", { className: "modal-image-container" },
            React.createElement("img", { ref: image, src: "/assets/icon/transparent.png", alt: `${photoLocation}-img` }),
            React.createElement("figcaption", null, photoLocation))));
}
