import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import moments from "assets/photos/meta";
export default function Navbar() {
    const location = useLocation();
    return (React.createElement("header", null,
        React.createElement("div", { id: "navbar" },
            React.createElement("div", { id: "navbar-name" },
                React.createElement(Link, { to: "/" }, "Shengyu Huang")),
            React.createElement("div", { id: "navigator" },
                React.createElement(Link, { to: "blog" },
                    React.createElement("span", { style: {
                            textDecoration: location.pathname.includes("blog") ? "underline" : "none",
                            textDecorationThickness: "0.05em",
                            textUnderlineOffset: "0.1em",
                        } }, "BLOG")),
                React.createElement(Link, { to: `moments?locations=${Object.keys(moments)
                        .sort((a, b) => (moments[a].location >= moments[b].location ? 1 : -1))
                        .join("%2C")}` },
                    React.createElement("span", { style: {
                            textDecoration: location.pathname.includes("moments") ? "underline" : "none",
                            textDecorationThickness: "0.05em",
                            textUnderlineOffset: "0.1em",
                        } }, "MOMENTS")),
                React.createElement(Link, { to: "about" },
                    React.createElement("span", { style: {
                            textDecoration: location.pathname.includes("about") ? "underline" : "none",
                            textDecorationThickness: "0.05em",
                            textUnderlineOffset: "0.1em",
                        } }, "ABOUT"))))));
}
