import React, { useEffect } from "react";
export default function ErrorPage() {
    useEffect(() => {
        document.title = "Page Not Found - Shengyu Huang";
    }, []);
    return (React.createElement("div", { id: "error" },
        React.createElement("h1", null, "PAGE NOT FOUND"),
        React.createElement("div", null, "whataya want from me?!"),
        React.createElement("img", { src: `/assets/img/error${window.innerWidth > 500 ? "" : "-small"}.jpeg`, alt: "error image" })));
}
