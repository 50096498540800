import React, { useEffect } from "react";
export default function About() {
    useEffect(() => {
        document.title = "About - Shengyu Huang";
    }, []);
    return (React.createElement("div", { id: "about" },
        React.createElement("figure", { id: "profile" },
            React.createElement("img", { src: `/assets/img/profile${window.innerWidth > 500 ? "" : "-small"}.jpeg`, alt: "profile", title: "Profile" })),
        React.createElement("div", { id: "about-text", className: "font-regular" },
            React.createElement("p", null, "Hi, I'm \u9EC4\u5347\u4FDE (hu\u00E1ng sh\u0113ng y\u00FA). I was born and raised in China. Later, I have resided in Germany, Switzerland, and Israel. This website is created to help me find a balance between the real and the virtual world. No likes from you are expected, so most of the materials would not be posted on my social media accounts. Photos found on this website are free for personal and commercial use though with attribution."),
            React.createElement("p", null, "Photos in the MOMENTS section are not ordered chronologically, because why would I want something old to fade from view simply because there is something new?"),
            React.createElement("p", null,
                React.createElement("em", null, "Nothing is less real than realism. Details are confusing. It is only by selection, by elimination, by emphasis, that we get at the real meaning of things.")),
            React.createElement("p", { style: { textAlign: "end" } }, "\u2015 Georgia O'Keeffe"))));
}
