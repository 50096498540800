const articles = {
    // fuerteventura: {
    //   title: "When things are not so pleasant",
    //   location: "Fuerteventura, Spain",
    //   happened: new Date("2022-08-11"),
    //   published: new Date("2022-08-14"),
    //   updated: new Date("2022-08-14"),
    //   text: "/assets/blog/fuerteventura/fuerteventura.md",
    //   thumbnail: "/assets/blog/fuerteventura/thumbnail.jpeg",
    // },
    // basel: {
    //   title: "Basel",
    //   location: "Basel, Switzerland",
    //   subtitle: "Two days before being unknown in that city",
    //   happened: new Date("2022-08-26"),
    //   published: new Date("2022-08-26"),
    //   updated: new Date("2022-08-26"),
    //   text: "/assets/blog/basel/basel.md",
    //   thumbnail: "/assets/blog/basel/thumbnail.jpeg"
    // },
    schrattenfluh: {
        title: "Schrattenfluh hike",
        location: "Flühli, Switzerland",
        subtitle: "To see the Schrattenkalk formation and a World War II bunker",
        happened: new Date("2022-08-01"),
        published: new Date("2022-08-03"),
        updated: new Date("2022-08-04"),
        text: "/assets/blog/schrattenfluh/schrattenfluh.md",
        thumbnail: "/assets/blog/schrattenfluh/thumbnail.jpeg"
    },
    "red-canyon": {
        title: "Red Canyon hike",
        location: "Eilat, Israel",
        happened: new Date("2021-11-30"),
        published: new Date("2022-08-04"),
        updated: new Date("2022-08-04"),
        text: "/assets/blog/red-canyon/red-canyon.md",
        thumbnail: "/assets/blog/red-canyon/thumbnail.jpg",
    },
    nanyuewang: {
        title: "西汉南越王博物馆 - 杨永德伉俪捐赠藏枕专题陈列",
        location: "Guangzhou, China",
        subtitle: "Chinese ceramic pillows curated by the Museum of the Western Han Dynasty Mausoleum of the Nanyue King",
        happened: new Date("2021-08-28"),
        published: new Date("2022-08-04"),
        updated: new Date("2022-08-04"),
        text: "/assets/blog/nanyuewang/nanyuewang.md",
        thumbnail: "/assets/blog/nanyuewang/thumbnail.jpg",
    },
    danxia: {
        title: "丹霞山长老峰一日游",
        location: "Shaoguan, China",
        subtitle: "To see the Danxia lanforms in the Mount Danxia",
        happened: new Date("2021-09-29"),
        published: new Date("2022-08-05"),
        updated: new Date("2022-08-05"),
        text: "/assets/blog/danxia/danxia.md",
        thumbnail: "/assets/blog/danxia/thumbnail.jpg",
    },
    first: {
        title: "An easy hike from First to Bachalpsee",
        location: "Grindelwald, Switzerland",
        happened: new Date("2022-07-03"),
        published: new Date("2022-08-05"),
        updated: new Date("2022-08-05"),
        text: "/assets/blog/first/first.md",
        thumbnail: "/assets/blog/first/thumbnail.jpeg",
    },
    stoos: {
        title: "A ridge hike from Klingenstock to Fronalpstock",
        location: "Stoos, Switzerland",
        happened: new Date("2022-07-14"),
        published: new Date("2022-08-05"),
        updated: new Date("2022-08-05"),
        text: "/assets/blog/stoos/stoos.md",
        thumbnail: "/assets/blog/stoos/thumbnail.jpeg",
    },
    "schaefler-saentis": {
        title: "A failed attempt to hike from Schäfler to Säntis",
        subtitle: "At least we caught the last train back home",
        location: "Appenzell, Switzerland",
        happened: new Date("2022-07-24"),
        published: new Date("2022-08-06"),
        updated: new Date("2022-08-06"),
        text: "/assets/blog/schaefler-saentis/schaefler-saentis.md",
        thumbnail: "/assets/blog/schaefler-saentis/thumbnail.jpeg",
    }
};
export default articles;
