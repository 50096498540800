import React, { useEffect, useRef } from "react";
import Date from "./Date";
import { lazyLoadImages } from "src/utils";
import { Link } from "react-router-dom";
export default function PostOverview(props) {
    const { article, id, sortBy } = props;
    const imageRef = useRef();
    useEffect(() => {
        if (imageRef.current) {
            lazyLoadImages([imageRef.current]);
        }
    }, []);
    return (React.createElement("div", { className: "overview" },
        React.createElement("div", { className: "meta-info" },
            React.createElement(Link, { to: `${id}` },
                React.createElement("div", { className: "title" }, article.title)),
            React.createElement(Subtitle, { subtitle: article.subtitle }),
            React.createElement("div", { className: "extra-meta grey" },
                React.createElement(Location, { location: article.location }),
                React.createElement("span", { className: "break" }, "|"),
                React.createElement(Date, { published: article.published, updated: article.updated, happened: article.happened, sortBy: sortBy, overviewMode: true }))),
        React.createElement(Link, { className: "blog-thumbnail", to: `${id}` },
            React.createElement("img", { ref: imageRef, src: "/assets/icon/transparent.png", "data-src": article.thumbnail, alt: "thumbnail-img" }))));
}
function Subtitle(props) {
    const { subtitle } = props;
    if (location) {
        return React.createElement("div", { className: "subtitle" }, subtitle);
    }
    else {
        return null;
    }
}
function Location(props) {
    const { location } = props;
    if (location) {
        return (React.createElement("div", { className: "location" },
            React.createElement("img", { className: "external-link", src: "/assets/icon/location.png", alt: "mail-icon" }),
            React.createElement("span", null, location)));
    }
    else {
        return null;
    }
}
