import React, { useEffect } from "react";
export default function HomePage() {
    useEffect(() => {
        document.title = "Shengyu Huang";
    }, []);
    return (React.createElement("div", { id: "home" },
        React.createElement("img", { src: `/assets/img/feature${window.innerWidth > 500 ? "" : "-small"}.jpeg`, alt: "feature image" }),
        React.createElement("div", null,
            React.createElement("p", null,
                "I spent most of my time during my teenage years in front of a computer. I guess that's why I developed",
                " ",
                React.createElement("em", null,
                    React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://de.wikipedia.org/wiki/Fernweh" }, "Fernweh")),
                " ",
                "after I went to Germany and later Switzerland for college. As a geek, I believed for a long time that the virtual world was bigger and better than the real world. However, those who were as lucky as me and found the opportunity to immerse in the real world would see that it simply cannot fit into a tiny computer (or a gigantic server whatsoever). By documenting the things that have amazed me, I hope this blog will inspire others to take a good look at their surroundings as well."),
            React.createElement("p", null,
                "This website endorses the principles suggested by",
                " ",
                React.createElement("a", { target: "_blank", rel: "noopener noreferrer", href: "https://lnt.org/social-media-guidance/" }, "Leave No Trace on Social Media Guidance"),
                "."))));
}
