const flashes = {
    "augusta-raurica": {
        location: "Augusta Raurica",
        excluded: [],
        numPhoto: 6,
    },
    "basel": {
        location: "Basel",
        excluded: [],
        numPhoto: 73,
    },
    "berlin": {
        location: "Berlin",
        excluded: [],
        numPhoto: 8,
    },
    "bj": {
        location: "Beijing",
        excluded: [],
        numPhoto: 4,
    },
    "como": {
        location: "Lake Como",
        excluded: [],
        numPhoto: 5,
    },
    "eilat": {
        location: "Eilat",
        excluded: [],
        numPhoto: 9,
    },
    "fue": {
        location: "Fuerteventura",
        excluded: [],
        numPhoto: 107,
    },
    "goettingen": {
        location: "Göttingen",
        excluded: [],
        numPhoto: 1,
    },
    "haifa": {
        location: "Haifa",
        excluded: [],
        numPhoto: 16,
    },
    "interlaken": {
        location: "Interlaken",
        excluded: [],
        numPhoto: 2,
    },
    "nyc": {
        location: "New York",
        excluded: [],
        numPhoto: 2,
    },
    "odda": {
        location: "Odda",
        excluded: [],
        numPhoto: 1,
    },
    "pilatus": {
        location: "Pilatus",
        excluded: [],
        numPhoto: 5,
    },
    "sg": {
        location: "Shaoguan",
        excluded: [],
        numPhoto: 6,
    },
    "shanghai": {
        location: "Shanghai",
        excluded: [],
        numPhoto: 1,
    },
    "dresden": {
        location: "Dresden",
        excluded: [],
        numPhoto: 5,
    },
    "gz": {
        location: "Guangzhou",
        excluded: [],
        numPhoto: 6,
    },
    "bremen": {
        location: "Bremen",
        excluded: [],
        numPhoto: 6,
    },
    "istanbul": {
        location: "Istanbul",
        excluded: [],
        numPhoto: 26,
    },
    "lausanne": {
        location: "Lausanne",
        excluded: [],
        numPhoto: 13,
    },
    "first": {
        location: "First",
        excluded: [],
        numPhoto: 12,
    },
    "stoos": {
        location: "Mount Stoos",
        excluded: [],
        numPhoto: 10,
    },
    "schrattenfluh": {
        location: "Schrattenfluh",
        excluded: [],
        numPhoto: 10,
    },
    "schaefler": {
        location: "Schäfler",
        excluded: [],
        numPhoto: 4,
    },
    "ebenalp": {
        location: "Ebenalp",
        excluded: [],
        numPhoto: 5,
    },
    "oeschinensee": {
        location: "Oeschinensee",
        excluded: [],
        numPhoto: 5,
    },
    "prague": {
        location: "Prague",
        excluded: [],
        numPhoto: 15,
    },
    "tel-aviv": {
        location: "Tel Aviv",
        excluded: [],
        numPhoto: 4,
    },
    "tromsoe": {
        location: "Tromsø",
        excluded: [],
        numPhoto: 1,
    },
    "zurich": {
        location: "Zürich",
        excluded: [],
        numPhoto: 16,
    },
};
export default flashes;
