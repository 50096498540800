import React, { useEffect, useRef, useState } from "react";
import * as marked from "marked";
import { lazyLoadImages } from "src/utils";
import Date from "./Date";
import articles from "assets/blog/meta";
import { useParams } from "react-router-dom";
import ErrorPage from "../ErrorPage";
export default function Post() {
    const { articleId } = useParams();
    const [content, setContent] = useState("");
    const [loadingText, setLoadingText] = useState(true);
    const [article, setArticle] = useState();
    const [unfound, setUnfound] = useState(false);
    const contentRef = useRef(null);
    useEffect(() => {
        const title = articleId.toLowerCase();
        try {
            const article = Object.entries(articles).find(([id, _]) => id.toLowerCase() === title)[1];
            setArticle(article);
            setUnfound(false);
        }
        catch {
            setArticle(null);
            setUnfound(true);
        }
    }, [articleId]);
    useEffect(() => {
        if (!article) {
            return;
        }
        document.title = article.title;
        if (article.text) {
            setLoadingText(true);
        }
        else {
            setLoadingText(false);
        }
    }, [article]);
    // fetch article's text
    useEffect(() => {
        if (!article || !article.text) {
            return;
        }
        const controller = new AbortController();
        contentRef.current.innerHTML = "";
        fetch(article.text, { signal: controller.signal })
            .then((res) => {
            if (controller.signal.aborted) {
                return "";
            }
            return res.text();
        })
            .then(setContent)
            .catch((e) => {
            if (!controller.signal.aborted) {
                console.log(e);
            }
        });
        return function cleanup() {
            article && controller.abort();
        };
    }, [article]);
    useEffect(() => {
        if (!article || !content || !article.text) {
            return;
        }
        marked.parse(content, (err, str) => {
            contentRef.current.innerHTML = err || str;
            setLoadingText(false);
            const images = [].slice.call(contentRef.current.querySelectorAll("img"));
            lazyLoadImages(images);
        });
    }, [article, content]);
    if (unfound) {
        return React.createElement(ErrorPage, null);
    }
    else {
        return (React.createElement("div", { className: "article" },
            article ? (React.createElement("div", null,
                React.createElement("h1", null, article.title),
                React.createElement(Date, { published: article.published, updated: article.updated, happened: article.happened, sortBy: "", overviewMode: false }))) : null,
            React.createElement("div", { style: { display: loadingText ? "block" : "none" } }, "Loading..."),
            React.createElement("div", { className: "content", ref: contentRef })));
    }
}
