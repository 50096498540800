import React, { useEffect, useState } from "react";
import PostOverview from "./PostOverview";
import Sorter from "./Sorter";
import articles from "assets/blog/meta";
export default function BlogOverview() {
    const [sortBy, setSortBy] = useState("updated");
    const [sortDirection, setSortDirection] = useState("down");
    useEffect(() => {
        document.title = "Blog - Shengyu Huang";
    }, []);
    function sortedArticles(articles) {
        return Object.entries(articles)
            .filter(([_, article]) => !article.unlisted)
            .sort((first, second) => {
            const article1 = first[1], article2 = second[1];
            if (sortBy === "published") {
                if (sortDirection === "down") {
                    return article1.published > article2.published ||
                        (article1.published.getTime() == article2.published.getTime() && article1.updated > article2.updated)
                        ? -1
                        : 1;
                }
                else {
                    return article1.published <= article2.published ? -1 : 1;
                }
            }
            else if (sortBy === "updated") {
                if (sortDirection === "down") {
                    return article1.updated > article2.updated ||
                        (article1.updated.getTime() == article2.updated.getTime() && article1.published > article2.published)
                        ? -1
                        : 1;
                }
                else {
                    return article1.updated <= article2.updated ? -1 : 1;
                }
            }
            else if (sortBy === "happened") {
                if (sortDirection === "down") {
                    return (article1.happened || article1.published) > (article2.happened || article2.published) ||
                        (article1.happened.getTime() == article2.happened.getTime() && article1.published > article2.published)
                        ? -1
                        : 1;
                }
                else {
                    return (article1.happened || article1.published) <= (article2.happened || article2.published) ? -1 : 1;
                }
            }
            else {
                return -1;
            }
        });
    }
    return (React.createElement("div", { id: "blog-overview" },
        React.createElement(Sorter, { sortBy: sortBy, setSortBy: setSortBy, sortDirection: sortDirection, setSortDirection: setSortDirection }),
        React.createElement("div", { id: "articles" }, sortedArticles(articles).map(([id, article]) => (React.createElement(React.Fragment, { key: id },
            React.createElement(PostOverview, { article: article, id: id, sortBy: sortBy })))))));
}
