import React from "react";
export default function Date(props) {
    const { published, updated, happened, sortBy, overviewMode } = props;
    if (overviewMode) {
        if (sortBy === "updated") {
            return React.createElement(UpdateDate, { date: updated, sortBy: sortBy });
        }
        else if (sortBy === "published") {
            return React.createElement(PublicationDate, { date: happened, sortBy: sortBy });
        }
        else {
            if (happened) {
                return React.createElement(EventDate, { date: happened, sortBy: sortBy });
            }
            else {
                return React.createElement(PublicationDate, { date: published, sortBy: sortBy });
            }
        }
    }
    else {
        return (React.createElement("div", { className: "grey date" },
            React.createElement(EventDate, { date: happened, sortBy: sortBy }),
            happened ? React.createElement("span", { className: "grey break" }, "|") : null,
            React.createElement(PublicationDate, { date: published, sortBy: sortBy }),
            React.createElement("span", { className: "grey break" }, "|"),
            React.createElement(UpdateDate, { date: updated, sortBy: sortBy })));
    }
}
function UpdateDate(props) {
    return React.createElement("span", null,
        "Update date: ",
        props.date.toDateString().split(" ").slice(1).join(" "));
}
function PublicationDate(props) {
    return React.createElement("span", null,
        "Publication date: ",
        props.date.toDateString().split(" ").slice(1).join(" "));
}
function EventDate(props) {
    if (props.date) {
        return React.createElement("span", null,
            "Event date: ",
            props.date.toDateString().split(" ").slice(1).join(" "));
    }
    else {
        return null;
    }
}
